import React from 'react';
import { Container } from 'react-bootstrap'; // Импортируем компонент Container из Bootstrap
import { useTranslation } from 'react-i18next';

function About() {
  const { t, i18n } = useTranslation();
  return (
    <section className="section" id="about">
      <Container> {/* Обертываем контент в компонент Container для Bootstrap */}
        <div className="mt-5 text-center section-content">
          <h2 className=" section-title">{t('header.about')}</h2>
          <p className="mt-3">{t('about.p')}</p><br></br>
          <p>{t('about.p2')}</p>
          <br></br>
          <p>{t('about.p3')}</p>
          <ol style={{maxWidth:"500px", margin:"0 auto"}} className='text-center'>
            <li>{t('about.li1')}</li>
            <li>{t('about.li2')}</li>
            <li>{t('about.li3')}</li>
            <li>{t('about.li4')}</li>
          </ol>
        </div>
      </Container>
    </section>
  );
}

export default About;
