// App.js
import React from 'react';
import HomePage from './pages/home';
import { Suspense } from 'react';
import { useTransition } from 'react';

import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <HomePage/>
  );
}

export default App;
