import React from 'react';
import { Carousel } from 'react-bootstrap';
import Slide1 from '../assets/images/slide1.jpg'
import Slide2 from '../assets/images/slide2.jpg'
import Slide3 from '../assets/images/slide3.jpg'
import Slide4 from '../assets/images/slide4.jpg'
import Slide5 from '../assets/images/slide5.JPG'
import Slide6 from '../assets/images/slide3.jpg'
import Slide7 from '../assets/images/slide2.jpg'
import Slide8 from '../assets/images/slide1.jpg'

function Slider() {
  return (
    <Carousel interval={null} style={{ maxHeight: '400px', overflow: 'hidden', marginTop:"10px" }}>
      <Carousel.Item>
        <div className="d-flex">
          <img
            className="w-25"
            src={Slide1}
            alt="First slide"
            style={{ maxHeight: '400px', objectFit: 'cover', marginRight: '10px' }}
          />
          <img
            className="w-25"
            src={Slide2}
            alt="Second slide"
            style={{ maxHeight: '400px', objectFit: 'cover', marginRight: '10px' }}
          />
          <img
            className="w-25"
            src={Slide3}
            alt="Third slide"
            style={{ maxHeight: '400px', objectFit: 'cover', marginRight: '10px' }}
          />
          <img
            className="w-25"
            src={Slide4}
            alt="Fourth slide"
            style={{ maxHeight: '400px', objectFit: 'cover' }}
          />
        </div>
        {/* <Carousel.Caption   style={{color:"red", background:"rgba(255, 255, 255, 0.5)", maxWidth:"400px", margin:"0 auto", borderRadius:"10px"}}>
          <h3>Զանգահարեք</h3>
          <p style={{fontWeight:"bold"}}>Հեռ. 098-01-87-88 </p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex">
          <img
            className="w-25"
            src={Slide5}
            alt="First slide"
            style={{ maxHeight: '400px', objectFit: 'cover', marginRight: '10px' }}
          />
          <img
            className="w-25"
            src={Slide6}
            alt="Second slide"
            style={{ maxHeight: '400px', objectFit: 'cover', marginRight: '10px' }}
          />
          <img
            className="w-25"
            src={Slide7}
            alt="Third slide"
            style={{ maxHeight: '400px', objectFit: 'cover', marginRight: '10px' }}
          />
          <img
            className="w-25"
            src={Slide8}
            alt="Fourth slide"
            style={{ maxHeight: '400px', objectFit: 'cover' }}
          />
        </div>
        {/* <Carousel.Caption    style={{color:"red", background:"rgba(255, 255, 255, 0.5)", maxWidth:"400px", margin:"0 auto", borderRadius:"10px"}}>
          <h3>Զանգահարեք</h3>
          <p style={{fontWeight:"bold"}}>Հեռ. 098-01-87-88 </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;
