// App.js

import React from 'react';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import HeroSection from '../components/heroSection';
import Slider from '../components/slider';
import ServicesAndPricing from '../components/service-price';
import Contact from '../components/contact';
import CallButton from '../components/call-btn';
import About from '../components/about';



function HomePage() {
  return (
    <Layout>
      <HeroSection />
      <Slider />
      <Container>
      <ServicesAndPricing />
      <About/>
      <CallButton/>
        <Contact />
      </Container>
    </Layout>
  );
}

export default HomePage;
