import React, { useRef, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
function Contact() {
  const [validated, setValidated] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [showThanks, setShowThanks] = useState(false);
  const form = useRef();
  const { t, i18n } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formElement = form.current;

  if (!formElement.checkValidity()) {
    e.stopPropagation();
    setValidated(true); // Set validated state to true to display validation messages
    return;
  }

    setValidated(true);

    // Custom phone validation
    const phoneField = form.elements["phone"];
    if (!isValidPhoneNumber(phoneField.value)) {
      e.preventDefault();
    } else {
      setPhoneError('');
      sendEmail();
    }
  };

  const isValidPhoneNumber = (phone) => {
    const phonePattern = /^\d{3}-\d{3}-\d{3}$/;
    return phonePattern.test(phone);
  };

  const sendEmail = (e) => {
    e.preventDefault()

    if (!form.current.checkValidity()) {
      e.stopPropagation();
      setValidated(true); // Set validated state to true to display validation messages
      return;
    }

    emailjs
      .sendForm('service_72zeslm', 'template_c58kozc', form.current, {
        publicKey: 'ls94dltIKC_VhgsAi',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="contact-section" id="contact">
      <Container className="mt-5 d-flex justify-content-center align-items-center">
        <div style={{maxWidth:"500px", width:"100%"}}>
          <h2 className="text-center mb-4">{t('header.contact')}</h2>
          {showThanks ? (
            <div id="thanks" className="text-center">
            {t('header.contact')}
            </div>
          ) : (
            <Form  ref={form} noValidate validated={validated} onSubmit={sendEmail}>
              <Form.Group controlId="name">
                <Form.Label>{t('header.contact')}</Form.Label>
                <Form.Control name="name" type="text" placeholder={t('contactForm.name')} required />
                <Form.Control.Feedback type="invalid">{t('contactForm.error.name')}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>{t('contactForm.email')}</Form.Label>
                <Form.Control name="email" type="email" placeholder={t('contactForm.email')} required />
                <Form.Control.Feedback type="invalid">{t('contactForm.error.email')}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>{t('contactForm.phone')}</Form.Label>
                <Form.Control name="phone" type="tel" placeholder={t('contactForm.phone')} required />
                <Form.Control.Feedback type="invalid">{t('contactForm.error.phone')}</Form.Control.Feedback>
                <Form.Text className="text-muted">{phoneError}</Form.Text>
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label>{t('contactForm.massage')}</Form.Label>
                <Form.Control name="message" as="textarea" style={{resize:"none"}} rows={3} placeholder={t('contactForm.massage')} required />
                <Form.Control.Feedback type="invalid">{t('contactForm.error.massage')}</Form.Control.Feedback>
              </Form.Group>
              <div className="text-center mt-4">
                <Button variant="primary" type="submit">
                {t('contactForm.submit')}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Contact;
