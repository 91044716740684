import React from 'react';
import { Container } from 'react-bootstrap';
import Trucks from "../assets/images/trucks.jpg";

function HeroSection() {
  return (
    <div style={{ maxHeight: '400px',overflow: 'hidden' }}>
      <img
        src={Trucks}
        alt="Hero Image"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <Container>
        {/* Возможно, вы хотите добавить текстовое содержимое поверх изображения */}
        <div className="text-white">
          {/* <h1>Company</h1>
          <p>Your description here. This section can contain any text or additional elements you want to display.</p> */}
        </div>
      </Container>
    </div>
  );
}

export default HeroSection;
