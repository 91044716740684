// Header.js
import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FaPhone } from 'react-icons/fa';
import Logo from "../assets/icons/truck1.png";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Header() {
  const phoneNumber = '098-01-87-88'; 
  const hrPhoneNumber = '37498018788';

  const { t, i18n } = useTranslation();

  const handleLanguageSelect = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary justify-content-center">
      <Container>
        <Navbar.Brand>
          <div className="align-items-center justify-content-center" style={{ display: "flex" }}>
            Evakuator-24-7.am
            <div style={{ maxWidth: "50px", marginLeft: "10px" }}>
              <img style={{ maxWidth: "100%" }} src={Logo} alt="site.am" />
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="me-auto">
            <Nav.Link href="#about">{t('header.about')}</Nav.Link>
            <Nav.Link href="#tariff">{t('header.service')}</Nav.Link>
            <Nav.Link href="#contact">{t('header.contact')}</Nav.Link>
          </Nav>
          <NavDropdown style={{marginRight:"20px"}} title={t('header.language')} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => handleLanguageSelect('en')}>English</NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLanguageSelect('ru')}>Русский</NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLanguageSelect('hy')}>Հայերեն</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
        <Nav.Link href={`tel:${hrPhoneNumber}`} className="d-flex align-items-center" style={{ backgroundColor: '#007bff', color: '#ffffff', padding: '5px 10px', borderRadius: '5px' }}>
          <FaPhone style={{ marginRight: '5px' }} />
          {phoneNumber}
        </Nav.Link>
      </Container>
    </Navbar>
  );
}

export default Header;
